angular.module('smiletrain')

.factory('BreakPoints', ["$window", "$rootScope", function ($window, $rootScope) {
  
  var breakPoints = [0, 390, 540, 960, 1140];
  var currentBreakpointIndex = -1;

  var getCurrentBreakpoint = function () {
    return breakPoints[currentBreakpointIndex];
  };

  var setBreakpoint = function () {
    var width = $window.innerWidth;

    for (var i = breakPoints.length - 1; i >= 0; i--) {

      var breakpoint = breakPoints[i];

      if (width >= breakpoint) {

        if (currentBreakpointIndex !== i) {
          currentBreakpointIndex = i;
          $rootScope.$broadcast('breakpoint', getCurrentBreakpoint());
        }

        break;
      }

    }
  };

  return {

    breakPoints: breakPoints,

    initialize: function () {
      setBreakpoint();
      $window.addEventListener('resize', setBreakpoint);
    },

    getCurrentBreakpoint: getCurrentBreakpoint 
  };

}]);