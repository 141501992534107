import "./svg.css";
import SvgHTML from "./svg.html";

angular.module('smiletrain')

.directive('stSvg', [function () {
  return {
    restrict: 'E',
    replace: true,
    template: SvgHTML,
    scope: {
      content: '<'
    }
  };
}]);