const _storage = window.localStorage || window.chrome.storage || false;

const _get = _storage.getItem || _storage.get;
const _set = _storage.setItem || _storage.set;
const _remove = _storage.removeItem || _storage.remove;

const _convert = function (item, set) {
  var _item;

  if(angular.isObject(item)) {
    _item = JSON.stringify(item);
  } else {
    try {
      // If setting JSON string we want to leave the string as is
      _item = set ? item : JSON.parse(item);
    } catch(e) {
      _item = item;
    }
  }

  return _item;
};

const Storage = {
  get (name) {
    if(_storage && _get) return _convert(_get.call(_storage, name));
  },
  set (name, value) {
    if(_storage && _set) _set.call(_storage, name, _convert(value, true));
  },
  remove (name) {
    if(_storage && _remove) _remove.call(_storage, name);
  }
};

export default Storage;