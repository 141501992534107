import "./tooltip.css";
import Tooltip from "./tooltip";

angular.module('smiletrain')

.directive('stTooltip', ["$parse", function ($parse) {

  return {
    restrict: 'A',
    replace: true,
    link: function (scope, element, attrs) {
      
      if (!Tooltip.initialized) {
        return;
      }

      element.on('mouseenter.tooltip', function () {
        
        var text = $parse(attrs.stTooltip)(scope);
        var position = attrs.tooltipPosition;

        Tooltip.show(element[0], text, position);

        element.on('click.tooltip', function () {
          Tooltip.hide();
        });

      });

      element.on('mouseleave.tooltip', function () {
        Tooltip.hide();

        element.off('click.tooltip');
      });
      
    }
  };

}]);