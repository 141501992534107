angular.module('smiletrain')

.directive('stTest', ["Human", function (Human) {
  return {
    restrict: 'A',
    scope: true,
    link: function (scope, element) {
      var human = Human.get();

      element.on('click', function () {
        human.send('quiz.enter');
      });
    }
  };
}]);