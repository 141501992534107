import App from "../../app/app.const";
import QuizSVG from "./img/quiz.inline.svg";

angular.module('smiletrain')

.controller('ContentController', ["$scope", "$location", "$route", "$routeParams", "Auth", "Human", "$sce", "BreakPoints", function ($scope, $location, $route, $routeParams, Auth, Human, $sce, BreakPoints) {

  $scope.quizSVG = QuizSVG;

  var _triggered = false;

  var playChapter = function () {

    if($scope.chapterActivated) {
      //Chapter has already activated. No need to do anything
      //but reset scope.chapterActivated
      $scope.chapterActivated = false;
    } else {
      //Chapter has not activated yet, let chapterActivated callback know
      //it doesn't need to update $location
      $scope.chapterPlaying = true;

      // Scrub to chapter if this is the first call (initial module load)
      // And current chapter does not have the animation propery
      var scrub = !_triggered && !$scope.curModule.curChapter.isAnimated;

      // Start playing/scrubbing to chapter, chapterActivated callback will
      // Fire here unless first chapter of newly loaded module, see below...
      if(scrub) {

        Human.scrub({
          chapterId: $scope.curModule.curChapter.chapterId
        });

      } else {
        
        Human.play({
          chapterId: $scope.curModule.curChapter.chapterId,
          numChapters: 1
        });

      }

      //Workaround: Chapter activated will not get called when playing the
      //first chapter of a newly loaded module due to RPC synch issues.
      //Manually trigger the event in that case
      if(!_triggered) _triggerActivateChapter();
    }
  };

  var detectChapterChange = function (id) {
    var routeParts = $location.path().match(/\/([^/]*)\/?([^/]*)?\/?/);

    if(routeParts && routeParts[1] === id) { // Only a chapter change
      var index = parseInt(routeParts[2] || '', 10);
      var chapterIndex = Number.isNaN(index) ? -1 : index;

      return chapterIndex;
    }
  };

  var _triggerActivateChapter = function () {
    var curModule = $scope.curModule;
    //only need to trigger if it's the first chapter
    if (curModule.curChapter.chapterId === curModule.chapters[0].chapterId) {
      $scope.$broadcast('activateChapter', curModule.curChapter);
    }
    //only need to check once, will be synched after that
    _triggered = true;
  };

  //Suppress this controller from being executed on a chapter change
  //This is a necessary hack until Angular improves their routing
  var lastRoute = $route.current;

  $scope.$on('$locationChangeSuccess', function() {
    var newChapterIndex = detectChapterChange($scope.curModule.id);
    
    // Only suppress if we're changing chapters on same module
    if(typeof newChapterIndex === 'number') {
      $route.current = lastRoute; // Suppression

      if(newChapterIndex >= 0) {
        var newChapter = $scope.curModule.chapters[newChapterIndex];
        $scope.setCurChapter(newChapter.id);
      } else {
        $location //enforce chapter parameter in url
          .path([$routeParams.moduleId, 0]
          .join('/'))
          .replace();
      }
    }
  });

  $scope.setCurChapter = function (chapterId) {
    if (chapterId) {
      $scope.curModule.curChapter = $scope.curModule.chapters.find(function (chapter) {
        return chapter.id === chapterId;
      });
    } else {
      $scope.curModule.curChapter = $scope.curModule.chapters[0];
    }

    if ($scope.curModule.curChapter) {
      playChapter();
    }
  };

  
  // Set initial curModule properties
  // The viewer directive will set additional properties that come from the API.
  var curModule = $scope.modules.find(function (module) {
    return module.id === $routeParams.moduleId;
  });

  angular.forEach(curModule, function (value, key) { //add new values
    $scope.curModule[key] = value;
  });


  function getWidgetUrl (humanDomain, modulePath, locale, userParams) {
    if(modulePath) {

      var widgetUrl = `/viewer/?id=${ modulePath }`;

      var widgetParams = {
        'ui-all': false,
        'ui-info': true,
        'ui-nav': BreakPoints.getCurrentBreakpoint() >= 540,
        'ui-bd': false,
        'ui-media-controls': 'none',
        'ui-fadeout': 0,
        'ui-loader': 'circle'
      };

      angular.forEach(widgetParams, function (value, key) {
        widgetUrl += ('&' + key + '=' + value);
      });

      angular.forEach(userParams, function (value, key) {
        widgetUrl += ('&' + key + '=' + value);
      });

      // Locale
      if (locale) {
        widgetUrl += '&lang=' + locale.id.split('_')[0];
      }

      // Do not attach protocol if we don't have a domain
      var protocol = humanDomain ? '//' : '';

      if (process.env.MOBILE) {
        return $sce.trustAsResourceUrl(widgetUrl);
      } else {
        return $sce.trustAsResourceUrl(protocol + humanDomain + widgetUrl);
      }
    }
  }
  
  function setWidgetURL () {
    var userParams = {
      'app-productkey': App.productKey,
      'app-userid': $scope.user.id || ''
    };

    $scope.widgetUrl = getWidgetUrl(
      $scope.environment.human,
      $scope.curModule.url,
      $scope.ui.locale,
      userParams
    );
  }

  $scope.$watchGroup(['environment', 'user'], function ([newEnvironment, newUser]) {
    if(
      newEnvironment && newEnvironment.human &&
      newUser && newUser.id
    ) {
      setWidgetURL();
    }
  });

}]);