import "../pages/content/content.css";
import "../pages/content/content.controller";
import ContentHTML from "../pages/content/content.html";

import "../pages/faq/faq.css";
import "../pages/faq/faq.controller";
import FaqHTML from "../pages/faq/faq.html";

import "../pages/home/home.css";
import "../pages/home/home.controller";
import HomeHTML from "../pages/home/home.html";

import "../pages/privacy/privacy.css";
import "../pages/privacy/privacy.controller";
import PrivacyHTML from "../pages/privacy/privacy.html";

import "../pages/references/references.css";
import "../pages/references/references.controller";
import ReferencesHTML from "../pages/references/references.html";

import "../pages/tos/tos.css";
import "../pages/tos/tos.controller";
import TosHTML from "../pages/tos/tos.html";

angular.module('smiletrain')

.config(["$locationProvider", "$routeProvider", function( $locationProvider, $routeProvider) {

  $locationProvider.hashPrefix('');

  var authResolver = function (access) {
    return {
      load: ["Auth", function (Auth) {
        return Auth.isSignedIn(access);
      }]
    };
  };

  $routeProvider.
    when('/', {
      template: HomeHTML,
      controller: 'HomeController',
      resolve: authResolver(0)
    }).
    when('/faq', {
      template: FaqHTML,
      controller: 'FAQController',
      resolve: authResolver(0)
    }).
    when('/tos', {
      template: TosHTML,
      controller: 'TOSController',
      resolve: authResolver(0)
    }).
    when('/privacy', {
      template: PrivacyHTML,
      controller: 'PrivacyController',
      resolve: authResolver(0)
    }).
    when('/references', {
      template: ReferencesHTML,
      controller: 'ReferencesController',
      resolve: authResolver(0)
    }).
    when('/:moduleId/:chapterIndex?', {
      template: ContentHTML,
      controller: 'ContentController',
      resolve: authResolver(1)
    }).
    otherwise({
      redirectTo: '/'
    });

}])