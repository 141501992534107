import "./respond-to-canvas.css";

angular.module('smiletrain')

.directive('respondToCanvas', ["Human", function (Human) {
  return {
    restrict: 'A',
    scope: true,
    link: function (scope, element, attrs) {
      var human = Human.get();

      var baseOffset = 12;

      var anchor = function (canvasInfo) {
        var clientRect = element[0].getBoundingClientRect();

        var offsetX = 0;
        var offsetY = 0;

        if (Object.prototype.hasOwnProperty.call(attrs, 'respondX')) {
          if (attrs.respondX === 'left') {

            offsetX =  canvasInfo.left + baseOffset;

          } else if (attrs.respondX === 'center') {
            offsetX = canvasInfo.left + (canvasInfo.width / 2) - (clientRect.width / 2);
            
          }
        }

        if (Object.prototype.hasOwnProperty.call(attrs, 'respondY')) {

          if (attrs.respondY === 'top') {

            offsetY = baseOffset;

          } else if (attrs.respondY === 'bottom') {

            offsetY = canvasInfo.height - clientRect.height - baseOffset;

          }
            
        }

        element[0].style.transform = `translate3d(${ offsetX }px, ${ offsetY }px, 0)`;
      };

      human.on('human.canvasResized', anchor);
      
      human.send('human.canvasInfo', function (canvasInfo) {
        anchor(canvasInfo);
        element[0].style.visibility = 'visible';
      });

    }
  };
}]);