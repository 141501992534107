import "./camera.css";
import CameraHTML from "./camera.html";

import LockedCameraSVG from "./img/locked-camera.inline.svg";
import UnlockedCameraSVG from "./img/unlocked-camera.inline.svg";

angular.module('smiletrain')

.directive('stCamera', ["Human", function (Human) {
  return {
    restrict: 'E',
    replace: false,
    scope: true,
    template: CameraHTML,
    link: function (scope) {

      scope.lockedCameraSVG = LockedCameraSVG;
      scope.unlockedCameraSVG = UnlockedCameraSVG;

      scope.cameraEnabled = true;

      scope.toggleCameraEnabled = function () {
        scope.cameraEnabled = !scope.cameraEnabled;

        var human = Human.get();

        if (scope.cameraEnabled) {
          human.send('camera.enableChapterCamera');
        } else {
          human.send('camera.disableChapterCamera');
        }
      };

    }
  }
}]);