angular.module('smiletrain')

.factory('Auth', ["$rootScope", "$http", "$location", function ($rootScope, $http, $location) {

  var signedIn;

  var signOut = function () {
    return $http.get('/ws/user/sign/out').then(
      function () {
        $rootScope.$broadcast('authChange', signedIn = false);
        $location.path('/');
      },
      function () {
        $location.path('/');
      }
    );
  };

  var checkSignIn = function () {    
    return $http.get('/ws/user/is/signed-in').then(
      function (response) {
        return response.status;
      },
      function (response) {
        return response.status;
      }
    )
  };

  var fetchUserProfile = function () {
    return $http({
      method: 'GET',
      url: '/ws/user/profile',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }).then(
      function (response) {
        $rootScope.$broadcast('userProfileChange', response.data);
        return response.data;
      },
      function () {
        var data = {};
        $rootScope.$broadcast('userProfileChange', data);
        return data;
      }
    );
  };

  var redirectToSignIn = function () {
    var pathName = encodeURIComponent($location.path());
    var fullUrl = `/signin.html?redirect_pathname=${ pathName }`;

    window.location.replace(fullUrl);
  };

  var isSignedIn = function (access) {
    
    if(signedIn) {
      
      return true;

    } else if (signedIn === false) {

      if (access === 1) {
        redirectToSignIn();
      }

      return false;

    } else { // Unresolved signedIn status, need to do a service check

      return checkSignIn().then(function (status) {

        if(status !== 401) { // Signed in
  
          $rootScope.$broadcast('authChange', signedIn = true);
          fetchUserProfile();
  
        } else { // Not signed in
  
          $rootScope.$broadcast('authChange', signedIn = false);
          
          if (access === 1) {
            redirectToSignIn();
          }

        }
        
        return signedIn;
      });

    }
    
  };

  var deleteAccount = function () {
    return $http.post('/ws/user/profile/delete').then(
      function () {
        window.location.assign('/'); // Reload app at homepage.
      },
      function (error) {
        $rootScope.$broadcast('deleteAccountError', error);
      }
    );
  }

  return {
    signOut:    signOut,
    isSignedIn: isSignedIn,
    deleteAccount: deleteAccount
  };

}]);