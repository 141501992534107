import Translations from "./translations.json";

import "./footer.css";
import FooterHTML from "./footer.html";

angular.module('smiletrain')

.directive('stFooter', ["$location", function ($location) {
  return {
    restrict: 'E',
    replace: true,
    template: FooterHTML,
    link: function (scope) {

      scope.location = $location.url().replace(/^\//, '');
      scope.curYear = new Date().getFullYear();

      scope.translations = {
        footer: Translations.footer
      };

    }
  };
}]);