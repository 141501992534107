
angular.module('smiletrain')

.factory('Environment', ["$http", function ($http) {

  var defaultEnvironment = { human: 'human.biodigital.com' };
  
  return {
    get: function () {
      return $http.get('/ws/environment/settings').then(
        function (response) {

          var environment = response.data;
          var human = environment.server_domain_name || environment.human;

          return { human };
        },
        function () {
          return defaultEnvironment;
        }
      );
    }
  };
}]);