import Tooltip from "../tooltip/tooltip";

angular.module('smiletrain')

.run(["$rootScope", "BreakPoints", function ($rootScope, BreakPoints) {

  var touchSupport = (('ontouchstart' in window) || window.DocumentTouch && document instanceof window.DocumentTouch);

  var touchClass = (touchSupport ? '' : 'no-') + 'touch';

  document.documentElement.className += (' ' + touchClass);

  $rootScope.touchSupport = touchSupport;

  $rootScope.$on('$locationChangeSuccess', function (e, newLoc) {
    ga('send', 'pageview', newLoc);
  });

  BreakPoints.initialize();

  if (!touchSupport) {
    Tooltip.initialize();
  }
 
}]);