import "./video.css";
import VideoHTML from "./video.html";

angular.module('smiletrain')

.directive('stVideo', ["$document", "Analytics", "Human", "BreakPoints", function ($document, Analytics, Human, BreakPoints) {

  var _createVideoFrame = function (src) {
    var videoEl = document.createElement('video');

    videoEl.src = src;
    videoEl.controls = true;
    videoEl.playsinline = true;
    videoEl.autoplay = true;

    return videoEl;
  };

  return {
    restrict: 'E',
    replace: true,
    template: VideoHTML,
    link :  function (scope, element) {

      var videoFrame, videoUrl, x, y, curX, curY, elData, parentData;
      var iframes;
      var parentEl = element.offsetParent();
      var container = element[0].querySelector('.st-video__container');
  
      var suppressFrames = function (suppress) {
        if(suppress) iframes = document.getElementsByTagName('iframe');
        //suppress iframes from slurping mousemove events
        var pointerEvents = suppress ? 'none' : 'initial';
  
        angular.forEach(iframes, function (iframe) {
          angular.element(iframe).css('pointer-events', pointerEvents);
        });
      };
  
      var dragVideo = function (e) {
        //add difference to current position
        x = element[0].offsetLeft + (e.clientX - curX);
        y = element[0].offsetTop  + (e.clientY - curY);
  
        //set constraints
        if(x < 0) x = 0;
        if(y < 0) y = 0;
  
        if(x + elData.width > parentData.width)
          x = parentData.width - elData.width;
  
        if(y + elData.height > parentData.height)
          y = parentData.height - elData.height;
  
        //update cur values for next call
        curX = e.clientX;
        curY = e.clientY;
  
        element.css({ 
          left: x + 'px', 
          top:  y + 'px', 
          right: 'auto', 
          bottom: 'auto'
        });
      };
  
      var stopDrag = function () {
        suppressFrames(false);
  
        $document.off('mousemove', dragVideo);
        $document.off('mouseup', stopDrag);
      };
  
      element.on('mousedown', function(e) {
        //e.preventDefault();
  
        elData     = element[0].getBoundingClientRect();
        parentData = parentEl[0].getBoundingClientRect();
  
        curX = e.clientX;
        curY = e.clientY;
  
        suppressFrames(true);
  
        $document.on('mousemove', dragVideo);
        $document.on('mouseup', stopDrag);
      });
  
      var positionVideo = function () {
  
        var breakpoint = BreakPoints.getCurrentBreakpoint();
  
        var startRight = 'auto';
        var startLeft = 'auto';
        var startTop = 'auto';
        var startBottom = 'auto';
  
        if (breakpoint >= 960) {
  
          startRight = '8px';
          startTop = '68px';
  
        } else {
  
          startLeft = '8px';
          startBottom = '132px';
  
        }
  
        element.css({ 
          left: startLeft,
          right: startRight,
          top: startTop,
          bottom: startBottom
        });
      };
  
      /* Functions related to video iframe source */
  
      var _addVideoFrame = function () {
        _removeVideoFrame(); //ensure removal of old
  
        videoFrame = _createVideoFrame(videoUrl);
        container.appendChild(videoFrame);
  
        Analytics.post('module.video.load', {
          category:   'module',
          identifier: scope.curModule._id,
          label:      scope.curModule._name,
          version:    '',
          url:        scope.video.src,
          source:     scope.ui.locale.id
        });
  
        var curChapter = scope.curModule.curChapter;
        var chapterName = [curChapter.number, curChapter.name].join(' ');
  
        amplitude.getInstance().logEvent('video load', {
          videoName: chapterName,
          moduleName: scope.curModule._name,
          url: scope.video.src
        });
  
      };
  
      var _removeVideoFrame = function () {
        if (videoFrame) {
          container.removeChild(videoFrame);
          videoFrame = null;
        }
      };
  
      scope.$watch('video', function (newVideo) {
        if(newVideo) {
          videoUrl = newVideo.src;
  
          if(scope.ui.video) {
            _addVideoFrame(); //if video display is out replace it with new
          } else {
            _removeVideoFrame(); //otherwise ensure old is removed
          }
  
        } else {
          _removeVideoFrame();
          scope.ui.video = false;
        }
      });
  
      scope.$watch('ui.testing', function (testing) {
        if(testing && scope.ui.video) scope.ui.video = false;
      });
  
      scope.$watch('ui.video', function (newUIVideo, oldUIVideo) {
  
        if (newUIVideo) {
          Human.pause();
        }
      
        //if turning on video display and we have a video, add the iframe
        if(newUIVideo && !oldUIVideo && videoUrl) {
          positionVideo();
          _addVideoFrame();
        }
        //if turning off video display remove iframe, in case the video is playing
        if(!newUIVideo && oldUIVideo) _removeVideoFrame();
      });
    }
  };
}]);