import "./settings.css";
import settingsHTML from "./settings.html";

function getUserOrganization (userProfile) {
  var organizations = userProfile.organizations;

  if (typeof organizations === 'string') {

    try {
      organizations = JSON.parse(organizations);  
    } catch (e) {
      organizations = null;
    }
    
  }

  if (!Array.isArray(organizations)) {
    return null;
  }

  return organizations[0] || null;
}

angular.module('smiletrain')

.directive('stSettings', ["$rootScope", "$timeout", "Auth", function ($rootScope, $timeout, Auth) {

  return {
    restrict: 'E',
    replace: true,
    scope: true,
    template: settingsHTML,
    link: function (scope, element) {

      scope.signOut = Auth.signOut;
      scope.canDeleteAccount = false;

      scope.deleteAccount = function () {
        $rootScope.$broadcast('modalOut', {
          content: 'deleteAccount',
          showHeader: true,
          showFooter: true,
          headerText: 'Delete Account',
          buttonText: 'Yes, Delete Account',
          buttonDisabled: true,
          showCancel: true,
          callback: function () {
            Auth.deleteAccount();
          }
        });
      };

      if (scope.touchSupport) {
  
        // Using ngSwipeRight directive to close panel
  
      } else {
  
        element.on('mouseleave', function () {
          $timeout(function () {
            scope.ui.menu = false;
          }, 500);
        });
  
      }
  
      var globalClose = function (e) {
        var outsideClick = !element[0].contains(e.target);
  
        if (outsideClick) {
          scope.ui.menu = false;
          scope.$apply();
        }
      };
  
      scope.$watch('ui.menu', function (uiMenu) {
        if (uiMenu) {
          window.document.addEventListener('touchstart', globalClose, true);
        } else {
          window.document.removeEventListener('touchstart', globalClose, true);
        }
      });
  
      // Close menu when current module id changes, user signs out,
      // Or settings menu opens.
      scope.$watch('curModule.id', function () {
        scope.ui.menu = false;
      });
  
      scope.$watch('signedIn', function (signedIn) {
        if (!signedIn) {
          scope.ui.menu = false;
        }
      });
  
      scope.$watch('ui.modules', function (uiModules) {
        if (uiModules) {
          scope.ui.menu = false;
        }
      });

      // Option to delete account is only for mobile builds
      if (process.env.MOBILE) {
        scope.$watch('user', function (userProfile) {
          if (Object.keys(userProfile).length > 0) {
            scope.canDeleteAccount = !getUserOrganization(userProfile);
          }
        });
      }

    }
  };
}]);