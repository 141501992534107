import Locales from "../locales/locales.const";
import Modules from "../modules/modules";
import Storage from "../storage/storage";

import "./app.css";
import AppHTML from "./app.html";

angular.module('smiletrain')

.directive('stApp', ["$location", "Environment", function ($location, Environment) {

  return {
    scope: true,
    replace: true,
    template: AppHTML,
    link: function (scope) {

      var resetCurModule = function () {
        scope.curModule = {
          chapters: [],
          curChapter: null,
          duration: 0
        };
      };

      var pageChange = function (curPath, oldPath) {
        curPath = curPath.replace(/^\//, '');
        oldPath = oldPath.replace(/^\//, '');

        return curPath.split('/')[0] !== oldPath.split('/')[0];
      };

      var moduleData = Modules.get();

      if(moduleData.groups) {
        scope.groups = moduleData.groups;
      }

      scope.modules = moduleData.modules;

      scope.curModule = {};

      scope.ui = {
        modules:  false,
        video:    false,
        menu:     false,
        testing:  false,
        locale:   Locales[0]
      };

      scope.user = {};

      var storedLocale = Storage.get('locale');

      if (storedLocale) {

        var locale = Locales.find(function (_locale) {
          return _locale.id === storedLocale.id;
        });

        if (locale) {
          scope.ui.locale = locale;
        }

      }

      scope.signedIn = undefined;

      scope.$on('authChange', function (e, authStatus) {
        scope.signedIn = authStatus;
      });

      scope.$on('userProfileChange', function (e, profile) {
        scope.user = profile;
        amplitude.getInstance().setUserId(profile.id);
      });

      Environment.get().then(function (environment) {
        scope.environment = environment;
      });

      var oldPath = '';

      scope.$on('$locationChangeSuccess', function() {
        var path = $location.path();

        if (pageChange(path, oldPath)) {
          resetCurModule();
        }

        oldPath = path;
      });

      resetCurModule();

      var newUser = Storage.get('new-user');

      if (newUser) {

        scope.$broadcast('modalOut', {
          content: 'disclaimer',
          showHeader: false,
          showFooter: true,
          headerText: '',
          buttonText: 'I Understand',
          buttonDisabled: false,
          showCancel: false,
          callback: null
        });

        Storage.remove('new-user');
      }
    } 
  };
  
}]);