
let curQuiz = null;

const Quiz = {
  getCurQuiz () {
    return curQuiz;
  },
  setCurQuiz (quizInfo) {
    curQuiz = quizInfo;
    curQuiz.complete = false;
  },
  clearCurQuiz () {
    curQuiz = null;
  },
  resetAnswers () {
    curQuiz.submissions.length = 0;
    curQuiz.complete = false;
  },
  recordAnswer (answerInfo) {
    curQuiz.submissions.push(answerInfo);
  },
  setComplete (complete) {
    curQuiz.complete = complete;
  },
  // Amplitude does not allow objects inside of arrays.
  buildResultsAnalytic () {
    var results = {};

    curQuiz.submissions.forEach(function (submission, i) {
      var question = curQuiz.questions[i];

      var questionText = question.questionPrompt.map(function (prompt) {
        return prompt.content;
      }).join(' ');

      var answerText = question.answers.find(function (answer) {
        return answer.answerId === submission.answerIds[0];
      }).displayText;

      var analytic = {
        question: questionText,
        answer: answerText,
        correct: submission.correct
      };

      results[i] = analytic;
    });

    return results;
  }
};

export default Quiz;