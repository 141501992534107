import Tooltip from "../tooltip/tooltip";

angular.module('smiletrain')

.factory('Scrubber', ["$document", function ($document) {

  var startEvents = 'touchstart mousedown';
  var moveEvents = 'touchmove mousemove';
  var endEvents = 'touchend touchcancel mouseup';

  var scrubberTemplate = [
    '<div class="track">',
    '<div class="chapters-container"></div>',
    '<div class="handle"></div>',
    '</div>'
  ].join('');

  var scrubbers = [];

  var Scrubber = function (config) {
    
    this.callbacks = {
      onStart: config.onStart || angular.noop,
      onMove: config.onMove || angular.noop,
      onEnd: config.onEnd || angular.noop
    };

    this.value = 0;
    this.duration = 0;

    this.chapters = [];
    this.chapterWidths = []; // In pixels
    this.chapterEls = [];
    this.chapterProgressEls = [];

    this.hoveredChapterEl;
    this.hoveredChapterRange = [];

    // Setup Elements
    config.element.innerHTML = scrubberTemplate;

    var scrubberEl = config.element;
    var chaptersContainerEl = scrubberEl.querySelector('.chapters-container');
    var trackEl = scrubberEl.querySelector('.track');
    var handleEl = scrubberEl.querySelector('.handle');

    this.elements = {
      container: config.element,
      track: trackEl,
      handle: handleEl,
      chaptersContainer: chaptersContainerEl
    };
    
    this.trackRect;
    
    this._setTrackRect();

    this.renderChapters;

    this.setRenderChapters(
      typeof config.renderChapters === 'boolean' ? config.renderChapters : true
    );

    var $track = angular.element(this.elements.track);

    angular.forEach(startEvents.split(' '), function (event) {
      $track.on(event + '.draggable', this.onStart.bind(this));
    }, this);

  };

  Scrubber.prototype.enableChapterHovering = function () {
    var scrubber = this;
    var $track = angular.element(this.elements.track);

    $track.on('mouseenter.chapterHovering', function (event) {
      scrubber._setTrackRect(); // Refresh

      var eventX = scrubber.getEventX(event);
      var enterValue = scrubber.getValue(eventX);
      var enterTime = enterValue * scrubber.duration;
      
      scrubber.setHoveredChapter(enterTime);
    });

    $track.on('mousemove.chapterHovering', function (event) {
      var eventX = scrubber.getEventX(event);
      var moveValue = scrubber.getValue(eventX);
      var moveTime = moveValue * scrubber.duration;

      if (
        moveTime < scrubber.hoveredChapterRange[0] || 
        moveTime >= scrubber.hoveredChapterRange[1]
      ) {  
       scrubber.setHoveredChapter(moveTime);
      }
    });

    $track.on('mouseleave.chapterHovering', function () {
      scrubber.unsetHoveredChapter();
    });
  };

  Scrubber.prototype.disableChapterHovering = function () {
    var $track = angular.element(this.elements.track);

    $track.off('mouseenter.chapterHovering');
    $track.off('mousemove.chapterHovering');
    $track.off('mouseleave.chapterHovering');
  };

  Scrubber.prototype.setHoveredChapter = function (time) {
    // Unset previous as there can only be one at a time
    this.unsetHoveredChapter();

    var hoveredChapterIndex = this.findChapterIndex(time);

    var hoveredChapter = this.chapters[hoveredChapterIndex];

    this.hoveredChapterRange[0] = hoveredChapter.time;
    this.hoveredChapterRange[1] = hoveredChapter.time + hoveredChapter.duration;

    this.hoveredChapterEl = this.chapterEls[hoveredChapterIndex];
    this.hoveredChapterEl.classList.add('hovering');

    var tooltipText = `${ hoveredChapterIndex + 1 }. ${ hoveredChapter.name }`;

    Tooltip.show(this.hoveredChapterEl, tooltipText);
  };

  Scrubber.prototype.unsetHoveredChapter = function () {
    if (this.hoveredChapterEl) {
      this.hoveredChapterEl.classList.remove('hovering');

      this.hoveredChapterEl = undefined;
      this.hoveredChapterRange.length = 0;

      Tooltip.hide();
    }
  }

  Scrubber.prototype.updateWidth = function () {
    this._setTrackRect();
    this._setChapterWidths();
    this._renderChapters();
    this._renderProgress();
  };

  Scrubber.prototype.onStart = function (e) {
    e.stopPropagation();
    e.preventDefault();

    this._setTrackRect(); // Refresh

    angular.forEach(moveEvents.split(' '), function (event) {
      $document.on(event + '.draggable', this.onMove.bind(this));
    }, this);

    angular.forEach(endEvents.split(' '), function (event) {
      $document.on(event + '.draggable', this.onEnd.bind(this));
    }, this);

    Tooltip.hide(); // Hide tooltip if necessary

    var eventX = this.getEventX(e);
    this.move(this.getValue(eventX));
    this.callbacks.onStart(this.value);
  };

  Scrubber.prototype.onMove = function (e) {
    var eventX = this.getEventX(e);
    this.move(this.getValue(eventX));
    this.callbacks.onMove(this.value);
  };

  Scrubber.prototype.onEnd = function () {
    angular.forEach(moveEvents.split(' '), function (event) {
      $document.off(event + '.draggable');
    }, this);

    angular.forEach(endEvents.split(' '), function (event) {
      $document.off(event + '.draggable');
    }, this);

    this.callbacks.onEnd();
  };

  Scrubber.prototype.getEventX = function (e) {
    e = e.originalEvent || e;
    e = e.changedTouches ? e.changedTouches[0] : e;

    return e.clientX;
  };

  Scrubber.prototype.getValue = function (x) {
    var positionX = x - this.trackRect.left;
    var value = positionX / this.trackRect.width;

    return Math.max( Math.min(value, 1), 0 );
  };

  // Set value directly without triggering callbacks
  Scrubber.prototype.move = function (value) {
    this.value = value;
    this._renderProgress();
  };

  Scrubber.prototype.findChapterIndex = function (time) {
    if (time === this.duration) { // Special case
      return this.chapters.length - 1;
    }

    for (var i = 0; i < this.chapters.length; i++) {
      var chapter = this.chapters[i];

      if (time >= chapter.time && time < chapter.time + chapter.duration) {
        return i;
      }
    }
  };

  Scrubber.prototype.setRenderChapters = function (renderChapters) {
    this.renderChapters = renderChapters;

    this._renderChapters();

    if (this.renderChapters) {
      this.enableChapterHovering();
    } else {
      this.disableChapterHovering();
      this.unsetHoveredChapter();
    }
  };

  Scrubber.prototype.setChapters = function (chapters) {
    this.chapters = chapters;

    this.chapterEls.length = 0;
    this.chapterProgressEls.length = 0;

    this.duration = this.chapters.reduce(function (duration, chapter) {
      return duration + chapter.duration;
    }, 0);

    this._buildChapterEls();
    this._setChapterWidths();
    this._renderChapters();
    this._renderProgress();
  };

  Scrubber.prototype._setTrackRect = function () {
    this.trackRect = this.elements.track.getBoundingClientRect();
  };

  Scrubber.prototype._renderProgress = function () {
    var x = this.value * this.trackRect.width;
    var currentTime = this.value * this.duration;

    if (x === x) { // NaN check

      // Set handle
      this.elements.handle.style.left = `${x}px`;

      // Set progress
      for (var i = 0; i < this.chapters.length; i++) {
        var chapter = this.chapters[i];
        var progressEl = this.chapterProgressEls[i];

        if (currentTime >= chapter.time) {

          if (currentTime < chapter.time + chapter.duration) {

            var chapterTime = currentTime - chapter.time;
            var chapterTimeScale = chapterTime / chapter.duration;
            var width = chapterTimeScale * this.chapterWidths[i];

            progressEl.style.width = `${ width }px`;

          } else {
            progressEl.style.width = `${ this.chapterWidths[i] }px`;
          }

        } else {

          progressEl.style.width = '0px';

        }

      }

    }
  };

  Scrubber.prototype._setChapterWidths = function () {
    this.chapterWidths.length = 0;

    this.chapters.forEach(function (chapter) {
      var chapterWidth = ((chapter.duration / this.duration) * this.trackRect.width);
      this.chapterWidths.push(chapterWidth);
    }, this);
  };

  Scrubber.prototype._renderChapters = function () {
    this.chapterEls.forEach(function (chapterEl, index) {
      var chapterWidth = this.chapterWidths[index];
      var marginRight = this.renderChapters ? 2 : 0;

      chapterEl.style.width = `${chapterWidth - marginRight}px`;
      chapterEl.style.marginRight = `${marginRight}px`;
    }, this);
  };

  Scrubber.prototype._buildChapterEls = function () {
    this.chapters.forEach(function () {
      var chapterEl = document.createElement('div');
      chapterEl.classList.add('chapter');

      var progressEl = document.createElement('div');
      progressEl.classList.add('chapter-progress');

      chapterEl.appendChild(progressEl);

      this.elements.chaptersContainer.appendChild(chapterEl);

      this.chapterProgressEls.push(progressEl);
      this.chapterEls.push(chapterEl);
    }, this);
  };

  return {
    create: function (config) {
      var slider = new Scrubber(config);
      scrubbers.push(slider);

      return slider;
    }
  };

}]);