import ModulesData from "./modules.json";

const Modules = {
  get () {

    const modules = ModulesData.modules;

    angular.forEach(modules, function (module) {
      
      // Module ids are used as url identifiers
      module.id = module.name.toLowerCase()
        .replace(/[()]+/g, '')    // Remove parentheses
        .replace(/[\s_]+/g, '-'); // Convert spaces/underscores to dashes

      module.thumbnailStyle = {
        backgroundImage: `url(${ module.thumbnailUrl })`
      };

    });

    return { modules };
  }
};

export default Modules;