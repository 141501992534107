import "./audio.css";
import AudioHTML from "./audio.html";

import AudioOnSVG from "./img/audio-on.inline.svg";
import AudioOffSVG from "./img/audio-off.inline.svg";

angular.module('smiletrain')

.directive('stAudio', ["Human", function (Human) {

  return {
    restrict: 'E',
    scope: true,
    replace: true,
    template: AudioHTML,
    link: function (scope, element) {
      var human = Human.get();
      var $audio = element.find('audio');
      var audioEl = $audio[0]; 

      scope.show = false;
      scope.playing = false;

      scope.audioOnSVG = AudioOnSVG;
      scope.audioOffSVG = AudioOffSVG;
  
      var setAutoplay = function (autoplay) {
        if (autoplay) {
          $audio.attr('autoplay', 'true');

          audioEl.play().catch(function (e) {
            console.log(`Smiletrain Audio: ${ e }`);
          });
  
        } else {
          $audio.removeAttr('autoplay');
          audioEl.pause();
        }

        // This is necessary to sync state after chapter changes
        scope.playing = !audioEl.paused;
        scope.$apply();
      };
  
      // When we enter a new chapter, check timeline playing state.
      // If playing and we're not testing then play audio.
      // These means if we scrub into a new chapter the audio will not autoplay.
      human.on('timeline.chapterTransition', function () {
  
        human.send('timeline.info', function(info) {
          setAutoplay(info.playing && !scope.ui.testing);
        });
        
      });

      scope.$watch('ui.testing', function (testing) {
        if(testing) {
          $audio.removeAttr('autoplay');
          audioEl.pause();
        } else {
          $audio.attr('autoplay', 'true');
        }
      });
  
      scope.$watch('ui.video', function (newUIVideo) {
        if(newUIVideo) {
          audioEl.pause();
        }
      });

      var firstAudio = true;
      
      scope.$watch('audio', function (newAudio) {
        if(newAudio) {
          audioEl.src = newAudio.src;

          if (firstAudio) {

            human.send('timeline.info', function(info) {
              setAutoplay(info.playing && !scope.ui.testing);
            });

          }

          firstAudio = false;
        } else {
          audioEl.src = '';
        }
  
        scope.show = !!newAudio;
      });
  
      // Controls
      var audioControls = element[0].querySelector('.st-audio__controls');
  
      var full = element[0].querySelector('.st-audio__slider');
      var played = element[0].querySelector('.st-audio__played');
  
      audioControls.addEventListener('mouseenter', function () {
        audioControls.classList.add('hovered');
      });
  
      audioControls.addEventListener('mouseleave', function () {
        audioControls.classList.remove('hovered');
      });
  
      scope.seekAudio = function (e) {
        audioEl.currentTime = (e.offsetX / full.offsetWidth) * audioEl.duration;
      };
  
      scope.togglePlay = function () {
        audioEl[audioEl.paused ? 'play' : 'pause']();
      };
  
      scope.$watch('audio', function () {
        played.style.width = 0;
      });

      // Audio Events
  
      audioEl.addEventListener('timeupdate', function () {
        var playedPercent = (this.currentTime / this.duration) * 100;
        played.style.width = playedPercent + '%';
      });

      audioEl.addEventListener('play', function () {
        scope.playing = true;
        scope.$apply();
      });

      audioEl.addEventListener('pause', function () {
        scope.playing = false;
        scope.$apply();
      });

      audioEl.addEventListener('ended', function () {
        scope.playing = false;
        scope.$apply();
      });

    }
  };
}]);