import "./survey.css";
import SurveyHTML from "./survey.html";

angular.module('smiletrain')

.directive('stSurvey', [function () {
  return {
      restrict: 'E',
      replace: false,
      template: SurveyHTML
  };
}]);