let containerEl;
let boundingBoxEl;
let tooltipPanelEl;
let tooltipEl;

const Tooltip = {

  initialized: false,

  initialize () {

    this.initialized = true;

    containerEl = document.createElement('div');
    containerEl.classList.add('st-tooltip-overlay-container');

    document.body.appendChild(containerEl);

    boundingBoxEl = document.createElement('div');
    boundingBoxEl.classList.add('st-tooltip-bounding-box');

    tooltipPanelEl = document.createElement('div');
    tooltipPanelEl.classList.add('st-tooltip-panel');

    tooltipEl = document.createElement('div');
    tooltipEl.classList.add('st-tooltip');

    tooltipPanelEl.appendChild(tooltipEl);
    boundingBoxEl.appendChild(tooltipPanelEl);

  },

  show (srcElement, text, position) {

    if (!this.initialized) {
      return;
    }

    this.hide();

    position = position || 'top';

    tooltipEl.textContent = text;

    containerEl.style.visibility = 'hidden';

    containerEl.appendChild(boundingBoxEl);

    const boundingBoxRect = boundingBoxEl.getBoundingClientRect();
    const srcElementRect = srcElement.getBoundingClientRect();
    const tooltipPanelRect = tooltipPanelEl.getBoundingClientRect();
    
    const widthDiff = tooltipPanelRect.width - srcElementRect.width;
    let tooltipPanelLeft = srcElementRect.left - widthDiff / 2;

    if (tooltipPanelLeft < 0) {
      tooltipPanelLeft = 0;
    }
    
    tooltipPanelEl.style.left = `${ tooltipPanelLeft }px`;

    if (position === 'top') {

      const tooltipPanelBottom = boundingBoxRect.bottom - srcElementRect.top;
      tooltipPanelEl.style.bottom = `${ tooltipPanelBottom }px`;

    } else if (position === 'bottom') {

      tooltipPanelEl.style.top = `${ srcElementRect.bottom }px`;

    }

    containerEl.style.visibility = null;
  },

  hide () {

    if (!this.initialized) {
      return;
    }

    tooltipEl.textContent = '';

    tooltipPanelEl.style.left = null;
    tooltipPanelEl.style.right = null;
    tooltipPanelEl.style.top = null;
    tooltipPanelEl.style.bottom = null;
    
    if (boundingBoxEl.parentElement === containerEl) {
      containerEl.removeChild(boundingBoxEl);
    }
    
  }

};

export default Tooltip;