import "./modules.css";
import ModulesHTML from "./modules.html";

angular.module('smiletrain')

.directive('stModules', ["$timeout", function ($timeout) {

  return {
    restrict: 'E',
    replace: true,
    template: ModulesHTML,
    link: function (scope, element) {

      if (scope.touchSupport) {
  
        // Using ngSwipeLeft directive to close panel
  
      } else {
  
        element.on('mouseleave', function () {
          $timeout(function () {
            scope.ui.modules = false;
          }, 500);
        });
  
      }
  
      var globalClose = function (e) {
        var outsideClick = !element[0].contains(e.target);
  
        if (outsideClick) {
          scope.ui.modules = false;
          scope.$apply();
        }
      };
  
      scope.$watch('ui.modules', function (uiModules) {
        if (uiModules) {
          window.document.addEventListener('touchstart', globalClose, true);
        } else {
          window.document.removeEventListener('touchstart', globalClose, true);
        }
      });
  
      // Close module list when current module id changes or settings menu opens
      scope.$watch('curModule.id', function () {
        scope.ui.modules = false;
      });
  
      scope.$watch('ui.menu', function (uiMenu) {
        if (uiMenu) {
          scope.ui.modules = false;
        }
      });
  
    }
  };
}]);