import ViewerId from "../human/viewer-id.const";

import "./timeline.css";
import TimelineHTML from "./timeline.html";

import PlaySVG from "./img/play.inline.svg";
import PauseSVG from "./img/pause.inline.svg";

angular.module('smiletrain')

.directive('stTimeline', ["$timeout", "Human", "Scrubber", "BreakPoints", function ($timeout, Human, Scrubber, BreakPoints) {
  return {
    restrict: 'E',
    scope: true,
    replace: true,
    template: TimelineHTML,
    link: function (scope, element) {

      scope.playSVG = PlaySVG;
      scope.pauseSVG = PauseSVG;

      scope.show = false;

      scope.state = Human.state;

      var human = Human.get();
      
      var endTime = 0;
      var dragging = false;
      var curChapterIndex = 0;
      var curChapter = null;

      var widgetIframe = document.getElementById(ViewerId);

      var playPauseBtn = element[0].querySelector('.st-timeline__play-pause');
      
      var scrubberEl = element.find('human-scrubber')[0];

      var scrubber = Scrubber.create({
        element: scrubberEl,
        renderChapters: BreakPoints.getCurrentBreakpoint() >= 960,
        onStart: function (fraction) {
            
          widgetIframe.style.pointerEvents = 'none';
          dragging = true;
          
          var time = endTime * fraction;
          Human.scrub({ time: time });
        },
        onMove: function (fraction) {
          var time = endTime * fraction;
          Human.scrub({ time: time });
        },
        onEnd: function () {
          widgetIframe.style.pointerEvents = 'initial';
          dragging = false;
        }
      });

      playPauseBtn.addEventListener('click', function () {

        if (Human.state.playing) {

          Human.pause();

        } else {

          human.send('timeline.info', function(info) {
            var globalTime = getGlobalTime(info.time);

            // Reset to beginning if we're at the end of the global timeline
            globalTime = globalTime === endTime ? 0 : globalTime;

            Human.play({ time: globalTime, loop: false });
            pingTimeline();
            
          });
            
        }
      });

      function pingTimeline() {

        human.send('timeline.info', function(info) {

          Human.setPlaying(info.playing);
          
          var timeScale = getGlobalTime(info.time) / endTime;

          scrubber.move(timeScale);

          if (Human.state.playing) {
            requestAnimationFrame(pingTimeline);
          }
        });
      }

      scope.$on('breakpoint', function (event, breakpoint) {
        scrubber.updateWidth();
        scrubber.setRenderChapters(breakpoint >= 960);
      });

      scope.$on('humanState.playing', function (event, state) {
          
        $timeout(function () {
          scope.state.playing = state.playing;
        });
          
      });

      human.on('timeline.chapterTransition', function (chapterTransition) {
        curChapterIndex = chapterTransition.currentChapter;
        curChapter = scope.curModule.chapters[curChapterIndex];

        if (!dragging) {
          pingTimeline();
        }
      });

      scope.$watch('curModule.chapters', function (_chapters) {
        scrubber.setChapters(_chapters);
        scope.show = _chapters.length > 0;

        curChapter = _chapters[curChapterIndex];

        // Global duration should be available now
        endTime = scope.curModule.duration;

        pingTimeline();
      });

      function getGlobalTime (localTime) {
        return curChapter.time + localTime;
      }

    }
  };
}]);