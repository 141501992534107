import "./camera-reset.css";
import CameraResetHTML from "./camera-reset.html";
import CameraResetSVG from "./img/camera-reset.inline.svg";

angular.module('smiletrain')

.directive('stCameraReset', ["Human", function (Human) {
  return {
    restrict: 'E',
    template: CameraResetHTML,
    link: function (scope, element) {
      
      scope.cameraResetSVG = CameraResetSVG;

      var human = Human.get();

      element.on('click', function () {
        human.send('camera.reset');
      });
    }
  };
}]);