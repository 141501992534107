import "./auth-check.css";
import AuthCheckHTML from "./auth-check.html";

angular.module('smiletrain')

.directive('stAuthCheck', [function () {
  return {
    restrict: 'E',
    replace: true,
    scope: true,
    template: AuthCheckHTML
  }
}]);