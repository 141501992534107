import "./modal.css";
import ModalHTML from "./modal.html";

angular.module('smiletrain')

.directive('stModal', [function () {
  return {
    restrict: 'E',
    replace: true,
    scope: true,
    template: ModalHTML,
    link: function (scope) {
      
      scope.modalOut = false;

      scope.showHeader = false;
      scope.showFooter = false;
      scope.content = '';
      scope.headerText = '';
      scope.buttonText = '';
      scope.buttonDisabled = false;
      scope.showCancel = false;
      scope.callback = null;

      scope.$on('modalOut', function (e, params) {
        scope.modalOut = true;
        
        scope.showHeader = params.showHeader;
        scope.showFooter = params.showFooter;
        scope.content = params.content;
        scope.headerText = params.headerText;
        scope.buttonText = params.buttonText;
        scope.buttonDisabled = params.buttonDisabled;
        scope.showCancel = params.showCancel;
        scope.callback = params.callback;
      });

      scope.$on('modalUpdate', function (e, params) {
        Object.assign(scope, params);
      });

      scope.closeModal = function () {
        scope.modalOut = false;
      };

      scope.onConfirm = function () {
        if (typeof scope.callback === 'function') {
          scope.callback(scope.closeModal);
        } else {
          scope.closeModal();
        }
      };

    }
  };
}]);