import Translations from "./translations.json";

angular.module('smiletrain')

.controller('FAQController', ["$scope", function ($scope) {
  
  $scope.generalQuestions = Translations.generalQuestions;
  $scope.technicalQuestions = Translations.technicalQuestions;
  $scope.system = $scope.technicalQuestions.system;
  $scope.loading = $scope.technicalQuestions.loading;

}]);