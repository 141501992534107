import "./delete-account.css";
import DeleteAccountHTML from "./delete-account.html";

angular.module('smiletrain')

.directive('stDeleteAccount', ["$rootScope", function ($rootScope) {
  return {
      restrict: 'E',
      scope: true,
      replace: false,
      template: DeleteAccountHTML,
      link: function (scope, element) {
        scope.error = false;

        scope.$on('deleteAccountError', function (e, err) {
          scope.error = true;

          var errorMessage = (err.data && err.data.error && err.data.error.message) || '';
          scope.errorMessage = `Unable to delete account (${ err.status }). ${ errorMessage }`;
        });

        var checkbox = element.find('input[type="checkbox"]')[0];

        if (checkbox) {
          checkbox.addEventListener('change', (event) => {
            $rootScope.$broadcast('modalUpdate', {
              buttonDisabled: !event.currentTarget.checked
            });
            $rootScope.$apply();
          });
        }

      }
  };
}]);