import Translations from "./translations.json";

import "./header.css";
import HeaderHTML from "./header.html";

import SettingsSVG from "./img/settings.inline.svg";
import ModulesSVG from "./img/modules.inline.svg";

angular.module('smiletrain')

.directive('stHeader', [function () {
  return {
    restrict: 'E',
    replace: true,
    template: HeaderHTML,
    link: function (scope) {

      scope.settingsSVG = SettingsSVG;
      scope.modulesSVG = ModulesSVG;

      scope.translations = {
        header: Translations.header
      };
      
    }
  };
}]);