import VideoControlsHTML from "./video-controls.html";
import VideoSVG from "./img/video.inline.svg";

angular.module('smiletrain')

.directive('stVideoControls', ["$timeout", function ($timeout) {

  return {
    restrict: 'E',
    replace: true,
    template: VideoControlsHTML,
    link: function (scope) {

      scope.videoSVG = VideoSVG;
      scope.show = false;
  
      scope.$watch('video', function (newVideo) {
        scope.show = !!newVideo;
  
        if(newVideo) {
          $timeout(function () { scope.newVideo = true; });
          $timeout(function () { scope.newVideo = false; }, 700);
        }
      });
    }
  };
}]);