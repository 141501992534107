import "./disclaimer.css";
import DisclaimerHTML from "./disclaimer.html";

angular.module('smiletrain')

.directive('stDisclaimer', [function () {
  return {
      restrict: 'E',
      replace: false,
      template: DisclaimerHTML
  };
}]);