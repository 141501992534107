angular.module('smiletrain')

.filter('translate', ["$sce", function ($sce) {
  return function () {

    var input, field, index, locale;

    if (arguments.length === 4) {
      input = arguments[0];
      field = arguments[1];
      index = arguments[2];
      locale = arguments[3];
    } else {
      input = arguments[0];
      field = arguments[1];
      locale = arguments[2];
    }

    if (!input || !input[field]) {
      return null;
    }

    var value = input[field];

    if (typeof index === 'number') {
      value = input[field][index];
    }

    var adaptFields = function (field, index) {
      var _value;

      if (typeof index === 'number' && field[index]) {
        return input.translations[locale.id][field][index];
      }

      //look for translation
      _value = input.translations[locale.id][field];
      //if field is name and none found, try displayName, (adaptation to engine)
      if(!_value && field === 'name') {
        _value = input.translations[locale.id].displayName;
      }
      //trust descriptions for HTML content
      if(_value && field === 'description') {
        _value = $sce.trustAsHtml(_value);
      }

      return _value;
    };

    if(input.translations && input.translations[locale.id]) {
      //return original if no translation is found
      value = adaptFields(field, index) || value;
    }

    return value;
  };
}]);