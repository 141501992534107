import Quiz from "../quiz/quiz";
import ViewerId from "../human/viewer-id.const";

import "./viewer.css";
import ViewerHTML from "./viewer.html";

angular.module('smiletrain')

.directive('stViewer', ["$location", "$routeParams", "Analytics", "Human", "BreakPoints", function ($location, $routeParams, Analytics, Human, BreakPoints) {

  var human;

  var initModule = function (scope) {

    human.send("scene.metadata", function(metadata) {

      // ---- MODULE -----

      var moduleId = scope.curModule.url.replace(/\.json$/, '');

      // Extend scope.curModule with some more properties, used by Analytics
      scope.curModule._id = moduleId;

      // This could be slightly different from scope.curModule.name,
      // Which is gotten from modules/index.json. In some cases those names may
      // Be more abbreviated than the displayName from the actual module's json.
      scope.curModule._name        = metadata.title;
      scope.curModule._description = metadata.description;

      Analytics.post('module.load', {
        category:   'module',
        identifier: scope.curModule._id,
        label:      scope.curModule._name,
        source:     scope.ui.locale.id
      });

      Analytics.post_GA('Load', {
        content_type: 'module',
        content_id: scope.curModule._id,
        content_name: scope.curModule._name
      }, scope.user);

      amplitude.getInstance().logEvent('module load', {
        moduleName: scope.curModule._name
      });

      // ----- TEST ------
      scope.ui.testing = false;
      scope.curModule.hasTest = false;

      human.send('quiz.info', function(quizInfo) {
        scope.curModule.hasTest = quizInfo && quizInfo.enabled;
      });      

      // ----- AUDIO / VIDEO ------

      var _getClips = function (mediaArray, type) {
        var clips = mediaArray.filter(function (clip) {
          return clip.streamType === type;
        });

        return clips;
      }

      // ---- CHAPTERS -----

      var idPool = {};

      var _generateId = function (displayName) {
        var _id = displayName.toLowerCase().replace(/[\s_/]/g, '-');

        // Keep track of chapter ids to ensure unique
        if(typeof idPool[_id] === 'number') {
          idPool[_id] += 1;
          _id = [_id, idPool[_id]].join('-');
        } else {
          idPool[_id] = 1;
        }

        return _id;
      };

      var chapters = [];
      var time = 0;

      // Normalize chapter properties
      angular.forEach(metadata.chapters, function (chapter, i) {

        var media = chapter.media || {};
        var mediaArray = Object.values(media);

        chapters.push({
          id:           _generateId(chapter.title),
          chapterId:    chapter.id,
          name:         chapter.title,
          number:       i + 1,
          description:  chapter.description,
          time:         time,
          duration:     chapter.duration.lastTime,
          isAnimated:   chapter.isAnimated,
          audio:        _getClips(mediaArray, 'audio'),
          video:        _getClips(mediaArray, 'video')
        });

        time += chapter.duration.lastTime;
      });

      scope.curModule.chapters = chapters;
      scope.curModule.duration = time;

      var hasIndex = Object.prototype.hasOwnProperty.call($routeParams, 'chapterIndex');

      if(hasIndex) {

        var index = parseInt($routeParams.chapterIndex, 10);
        var chapterIndex = Number.isNaN(index) ? 0 : index;
        var chapter = chapters[chapterIndex];

        if (chapter) {
          scope.setCurChapter(chapter.id);
        }
        
      } else {
        if(chapters.length === 0) {
          
          // No chapters

        } else {
          
          // If we load a module with chapters and the chapter id is not
          // In $routeParams, add first chapter id and replace browser history.
          var curPath = $location.path();
          $location.path([curPath, 0].join('/')).replace();

        }
      }

      scope.$apply();
    });

    var _suggestClip = function (clips, type) {

      var clip;

      if (clips && clips.length > 0) {
        var lang = scope.ui.locale.id.split('_')[0];

        // Special case for video
        // We only have english so show this for all languages.
        if (type === 'video') {

          clip = clips[0];

        } else {

          clip = clips.find((_clip) => {
            return _clip.lang === lang;  
          });

        }

       

      }

      if (clip) {
        
        scope[type] = {
          displayName:  clip.displayName,
          src:          clip.fullUrl
        };

      } else {
        scope[type] = null;
      }
    };

    var _activateChapter = function (chapter, noApply) {
      
      var chapterName = [chapter.number, chapter.name].join(' ');

      Analytics.post('module.chapter.load', {
        category:   'module',
        identifier: scope.curModule._id,
        label:      scope.curModule._name,
        version:    chapterName,
        source:     scope.ui.locale.id
      });

      Analytics.post_GA('Load Chapter', {
          content_type: 'module',
          content_id: scope.curModule._id,
          content_name: scope.curModule._name,
          chapter_name: chapterName
      }, scope.user);

      amplitude.getInstance().logEvent('chapter load', {
        chapterName: chapterName,
        moduleName: scope.curModule._name,
      });

      // Audio / Video
      _suggestClip(chapter.audio, 'audio');
      _suggestClip(chapter.video, 'video');

      if(scope.chapterPlaying) {
        //$location change happened first. No need to do anything
        //but reset scope.chapterPlaying
        scope.chapterPlaying = false;
      } else {
        //$location change has not happend yet. Update $location and let
        //controller know the chapter is already activated
        scope.chapterActivated = true;

        // Path by index
        var chapterIndex = chapter.number - 1;
        var newPath = [$routeParams.moduleId, chapterIndex].join('/');

        $location.path(newPath); //updates path programmatically
      }

      if(!noApply) scope.$apply();
    };

    human.on('timeline.chapterTransition', function (data) {
      var chapter = scope.curModule.chapters[data.currentChapter];
      _activateChapter(chapter, false);
    });

    //chapterActivated event will not get triggered when playing first chapter
    //of an initially loaded module. We'll manually trigger it via this event.
    scope.$on('activateChapter', function (e, chapter) {
      //since this is coming from controller, suppress scope.$apply
      _activateChapter(chapter, true);
    });

  };

  return {
    restrict: 'E',
    replace: true,
    template: ViewerHTML,
    link:  function (scope, element) {

      element[0].id = ViewerId;

      if (human) {
        Human.destroy();
      }
  
      Human.create();
  
      human = Human.get();
  
      initModule(scope);
  
      human.on('quiz.entered', function (quizInfo) {
        Human.pause();
  
        Quiz.setCurQuiz(quizInfo);
  
        amplitude.getInstance().logEvent('test start', {
          moduleName: scope.curModule._name
        });
  
        scope.ui.testing = true;
        scope.$apply();
      });
  
      human.on('quiz.exited', function () {
  
        if (!Quiz.getCurQuiz().complete) {
  
          amplitude.getInstance().logEvent('test end', {
            moduleName: scope.curModule._name,
            results: Quiz.buildResultsAnalytic()
          });
  
        }
  
        Quiz.clearCurQuiz();
  
        scope.ui.testing = false;
        scope.$apply();
      });
  
      human.on('quiz.answerSubmitted', function (answerInfo) {
        Quiz.recordAnswer(answerInfo);
      });
  
      human.on('quiz.questionLoaded', function (questionInfo) {
        
        var curQuiz = Quiz.getCurQuiz();
        
        // The initial question loaded event is currently firing before 'quiz.entered'
        if (curQuiz) {        
          // Ensure answers are reset when we're on the first question
          if (questionInfo.id === curQuiz.questions[0].questionId) {
            Quiz.resetAnswers();
          }
        }
      });
      
      human.on('quiz.completed', function () {
  
        Quiz.setComplete(true);
        
        amplitude.getInstance().logEvent('test complete', {
          moduleName: scope.curModule._name,
          results: Quiz.buildResultsAnalytic()
        });
  
      });
  
      scope.$on('$destroy', function () {
        Human.destroy();
      });
  
      human.send('ui.setDisplay', { 
        nav: BreakPoints.getCurrentBreakpoint() >= 540
      });
  
      scope.$on('breakpoint', function (event, breakpoint) {
        if (breakpoint >= 540) {
            human.send('ui.setDisplay', { nav: true });
        } else {
            human.send('ui.setDisplay', { nav: false });
        }
      });
  
    }
  };
}]);