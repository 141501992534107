import App from "../app/app.const";

angular.module('smiletrain')

.factory('Analytics', ["$http", "$window", function ($http, $window) {

  var APP_TITLE = 'Smile Train: VSS';

  var TYPES = {
    'module.load':         'loading a module',
    'module.chapter.load': 'loading a module chapter',
    'module.video.load':   'loading a module video',
    'module.test.start':   'starting a module test'
  };

  var GA_TYPES = {
    'Load': 'loading a module', 
    'Load Chapter': 'loading a module chapter'
  };

  var baseAnalytic = {
    'analytic_platform': 'hosted_app',
    'analytic_app_name': App.name
  };

  var _isListedType = function (type) {
    return Object.prototype.hasOwnProperty.call(TYPES, type);
  };

  var _isListedGAType = function (type) {
    return Object.prototype.hasOwnProperty.call(GA_TYPES, type);
  };

  return {
    post: function (_type, data) {
      
      // Short circuit for non-analytic apps or types that aren't whitelisted
      if(!_isListedType(_type)) {
        return;
      }

      var analytic = angular.extend({}, baseAnalytic);
      var type = [analytic['analytic_app_name'], _type].join('.');

      analytic['analytic_type'] = type;
      analytic['analytic_description'] = TYPES[_type];
      analytic['analytic_data'] = JSON.stringify(data);

      $http({
        method: 'POST',
        url: '/ws/user/analytics',
        data: analytic,
        transformRequest: function (data) { //manually serialize/encode
          var _data = [];
          angular.forEach(data, function (value, key) {
            _data.push([encodeURIComponent(key), encodeURIComponent(value)]
              .join('='));
          });

          return _data.join('&');
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
      });
    },
    post_GA: function (_type, data, user) {
        
        // Short circuit for non-analytic apps or types that aren't whitelisted
        if (!_isListedGAType(_type)) {
          return;
        }

        var platform_type = "Web";
        var app_name = APP_TITLE;
        var app_domain = $window.document.domain || $window.location.host;
        var alt_name = data.content_id.split('/');
        alt_name = alt_name[alt_name.length - 1].replace('.json', '');
        var content_name = !data.content_name ||
            data.content_name == "" ? "[unspecified] " + alt_name : data.content_name;
        var event_category = "Content";
        var event_action = _type;
        var event_label = data.chapter_name ? [content_name, data.chapter_name].join(' : ') : content_name;
        var chapter_name = "";
        if (data.chapter_name) {
            chapter_name = data.chapter_name;
        }
        var user_id = "";

        if (user) {
            user_id = user.id ? user.id.toString() : user_id;
        }

        ga('set', {
            'dimension8': platform_type,
            'dimension9': app_name,
            'dimension4': data.content_type,
            'dimension3': data.content_id,
            'dimension1': content_name,
            'dimension6': app_domain,
            'dimension2': chapter_name,
            'dimension7': user_id,
            'userId': user_id
        });
        ga('send', {
            hitType: 'event',
            eventCategory: event_category,
            eventAction: event_action,
            eventLabel: event_label
        });
        ga('set', {
            'dimension8': null,
            'dimension9': null,
            'dimension4': null,
            'dimension3': null,
            'dimension1': null,
            'dimension6': null,
            'dimension2': null,
            'dimension7': null,
            'userId': null
        });

    }
  };
}]);