import Storage from "../storage/storage";

import "./survey-trigger.css";
import SurveyTriggerHTML from "./survey-trigger.html";

angular.module('smiletrain')

.directive('stSurveyTrigger', ["$rootScope", function ($rootScope) {
  return {
    restrict: 'E',
    scope: true,
    replace: true,
    template: SurveyTriggerHTML,
    link: function (scope) {

      scope.show = !Storage.get('survey-panel-seen') && scope.ui.locale.id === 'en_US';
      
      scope.triggerModal = function () {

        $rootScope.$broadcast('modalOut', {
          content: 'survey',
          showHeader: true,
          showFooter: false,
          headerText: 'Survey',
          buttonText: '',
          buttonDisabled: false,
          showCancel: false,
          callback: null
        });

        scope.setSeen();
      };

      scope.setSeen = function () {
        Storage.set('survey-panel-seen', true);
        scope.show = false;
      };

    }
  };
}]);