import Translations from "./translations.json";

angular.module('smiletrain')

.controller('HomeController', ["$scope", function ($scope) {
  
  $scope.showcase = Translations.showcase;
  $scope.about = Translations.about;
  $scope.features = Translations.features;
  $scope.vision = Translations.vision;
  $scope.asseenin = Translations.asseenin;
  $scope.bios = Translations.bios;

  $scope.bioIndex = -1;
    
  $scope.toggleBio = function (index) {
    $scope.bioIndex = $scope.bioIndex === index ? -1 : index;
  };

}]);