import Storage from "../storage/storage";
import Locales from "./locales.const";

import "./locales.css";
import LocalesHTML from "./locales.html";

angular.module('smiletrain')

.directive('stLocales', ["$window", function ($window) {
  return {
    restrict: 'E',
    scope: true,
    replace: true,
    template: LocalesHTML,
    link: function (scope) {

      scope.locales = Locales;
      scope.localesOut = false;

      scope.setLocale = function (locale) {
        
        Storage.set('locale',{ id: locale.id, name: locale.name });
        
        amplitude.getInstance().identify(
          new amplitude.Identify().set('locale', locale.id)
        );

        // Hide body before reloading for cleaner experience
        $window.document.body.style.display = 'none';
        $window.location.reload();
      };

    }
  };
}]);