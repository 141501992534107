import "../../lib/human-api";
import ViewerId from "./viewer-id.const";

angular.module('smiletrain')

.factory('Human', ["$rootScope", function ($rootScope) {

  var human = null;

  return {
    
    state: {
      playing: false
    },

    get: function () {
      return human;
    },
    create: function () {
      human = new HumanAPI(ViewerId);

      var self = this;

      human.on('timeline.chapterTransition', function () {
        
        human.send('timeline.info', function(info) {
          self.setPlaying(info.playing);
        });

      });

    },
    destroy: function () {
      if (human) {
        human._rpc.destroy();
      }
    },
    play: function (params) {
      human.send('timeline.play', params);
      this.setPlaying(true);
    },
    scrub: function (params) {
      human.send('timeline.set', params);
      this.setPlaying(false);
    },
    pause: function () {
      human.send('timeline.pause');
      this.setPlaying(false);
    },
    setPlaying: function (playing) {

      var change = playing !== this.state.playing;

      this.state.playing = playing;

      if (change) {
        $rootScope.$broadcast('humanState.playing', {
          playing: this.state.playing
         });
      }
    }
  };
}]);